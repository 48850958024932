import styled, { css } from 'styled-components';
import { Colors } from 'styles';

type ButtonStyleOption = 'blue' | 'orange';

interface ButtonStyle {
  btStyle?: ButtonStyleOption;
  disabled?: boolean;
}

const styleButton = {
  orange: css`
    background-color: ${Colors.Blue};
    color: #fff;
  `,
  blue: css`
    background-color: #3b81f5;
    color: #fff;
  `,
};

export const Button = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  padding: 20px 48px;
  min-height: 50px;
  border-radius: 32px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.blue)}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${Colors.Gray100}80;
      color: ${Colors.Gray50}40;
      cursor: not-allowed;
      :hover {
        transform: unset;
      }
    `}
`;

export const ButtonMini = styled.button<ButtonStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 14px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.95);
  }

  ${({ btStyle }) => (btStyle ? styleButton[btStyle] : styleButton.orange)}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${Colors.Gray100}80;
      color: ${Colors.Gray50}40;
      cursor: not-allowed;
      :hover {
        transform: unset;
      }
    `}
`;
