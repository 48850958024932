import styled from 'styled-components';
import {
  PageContainer,
  PageContent,
  ArrowBack,
} from 'styles/styled-components';
import { Colors, fonts } from 'styles';
export { Button, FormRow, FormFooter, Loading } from 'styles/styled-components';

export const Container = styled(PageContainer)``;

export const Content = styled(PageContent)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
`;

export const BackButton = styled.button.attrs({ type: 'button' })`
  display: flex;
`;

export const IconBack = styled(ArrowBack).attrs({ size: 32 })`
  color: ${Colors.Blue};
`;

export const Title = styled.h1`
  width: 100%;
  max-width: 50%;
  font-family: ${fonts.GilroyLight};
  font-size: 40px;
  line-height: 40px;
  color: ${Colors.Gray100};
  z-index: 1;
`;
