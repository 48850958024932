import styled, { css } from 'styled-components';
import { Colors } from 'styles';
import illustrationBg from 'assets/images/illustration-bg.svg';
import illustrationBuildings from 'assets/images/illustration-buildings.svg';
import illustrationPackages from 'assets/images/illustration-packages.svg';
import illustrationPin from 'assets/images/illustration-pin.svg';
import illustrationTrees1 from 'assets/images/illustration-trees-1.svg';
import illustrationTrees2 from 'assets/images/illustration-trees-2.svg';
import illustrationTruck from 'assets/images/illustration-truck.svg';

interface IPosition {
  position: number;
}

export { Loading } from 'styles/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 150px;
  position: relative;
  margin-bottom: 88px;
  z-index: 0;
`;

export const Line = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${Colors.Gray100};
  position: relative;
  z-index: 10;
`;

export const IllustrationBg = styled.img.attrs({ src: illustrationBg })`
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100%;
`;

export const IllustrationBuildings = styled.img.attrs({
  src: illustrationBuildings,
})`
  position: absolute;
  left: 30%;
  z-index: 1;
`;

export const IllustrationPackages = styled.img.attrs({
  src: illustrationPackages,
})`
  position: absolute;
  left: 0;
  z-index: 3;
`;

export const IllustrationPin = styled.img.attrs({ src: illustrationPin })`
  position: absolute;
  right: 0;
  z-index: 3;
`;

export const IllustrationTree1 = styled.img.attrs({ src: illustrationTrees1 })`
  position: absolute;
  left: 15%;
  z-index: 2;
`;

export const IllustrationTree2 = styled.img.attrs({ src: illustrationTrees2 })`
  position: absolute;
  right: 15%;
  z-index: 2;
`;

export const Truck = styled.div<IPosition>`
  transition: 2s ease;
  animation-delay: 1s;
  position: absolute;
  z-index: 4;
  left: ${({ position }) => (position > 1 ? `calc(${position}% - 65px)` : '0')};
`;

export const IllustrationTruck = styled.img.attrs({ src: illustrationTruck })`
  position: absolute;
  bottom: 0;
`;

export const DistanceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: -28px;
  background-color: #ccc;
`;

export const DistanceToFinishedLine = styled.div<IPosition>`
  width: ${({ position }) =>
    position > 0 ? `calc(${position}% + 32px)` : '100%'};
  max-width: 100%;
  height: 2px;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: ${Colors.Blue};

  ${({ position }) =>
    position <= 1 &&
    css`
      display: none;
    `}
`;

export const DistanceLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.Gray50};
`;

export const Ball = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${Colors.Gray50};
`;

export const BallStart = styled(Ball)`
  position: absolute;
  left: 0;
  z-index: 2;
`;

export const BallEnd = styled(Ball)`
  position: absolute;
  right: 0;
  z-index: 2;
`;

export const BallTruck = styled(Ball)<IPosition>`
  position: absolute;
  z-index: 2;
  left: ${({ position }) =>
    position > 1 ? `calc(${position}% - 32px)` : '32px'};

  ${({ position }) =>
    position < 1 &&
    css`
      display: none;
    `}

  ${({ position }) =>
    position > 97 &&
    css`
      display: none;
    `}
`;

export const Label = styled.h6`
  font-size: 12px;
  color: ${Colors.Gray50};
`;

export const LabelStart = styled(Label)`
  position: absolute;
  left: 0;
  bottom: -24px;
`;

export const LabelEnd = styled(Label)`
  position: absolute;
  right: 0;
  bottom: -24px;
`;

export const LabelTruck = styled(Label)<IPosition>`
  position: absolute;
  left: ${({ position }) =>
    position > 1 ? `calc(${position}% - 82px)` : '82px'};
  bottom: -24px;
  text-align: right;

  ${({ position }) =>
    position < 1 &&
    css`
      display: none;
    `}

  ${({ position }) =>
    position > 97 &&
    css`
      display: none;
    `}
`;

export const LabelPositionToFinished = styled(Label)`
  position: absolute;
  top: -20px;
  right: 45%;
  text-align: center;
  color: ${Colors.Blue};
`;
