export enum StatusColors {
  transito = '#ED6C1F',
  'transito-atraso' = '#EF3131',
  'transito-sem-prazo' = '#ED6C1F',
  entregue = '#70982B',
  'entregue-cliente' = '#70982B',
  'entregue-atraso' = '#70982B',
  'entregue-sem-prazo' = '#70982B',
  'sem-status' = '#777777',
  cancelada = '#EF3131',
  devolucao = '#EF3131',
  'chegou-cliente' = '#ED6C1F',
}

export enum Colors {
  White = '#FFFFFF',
  Orange = '#EE7C2C',
  Orange20 = '#F5E9E1',
  Gray05 = '#f8f8f8',
  Gray10 = '#E9E9E9',
  Gray20 = '#D1D1D1',
  Gray50 = '#88888C',
  Gray100 = '#2E2E36',
  Red = '#EF3131',
  Green = '#70982B',
  Blue = '#3B81F5',
  Blue20 = '#DAE9FD',
}
